import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  Input,
  Avatar,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { login } from "../../schemas";
import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "./../Helper/APIURL";

const initialValues = {
  email: "",
  password: "",
};

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("ID");
    if (token&&id) {
      navigate("/");
    }
  }, []);

  const handlelogin = (event) => {
    setLoading(true);
    const data = JSON.stringify({
      email: values.email,
      password: values.password,
    });

    const config = {
      method: "post",
      url: BASE_URL + "auth/login",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        if ((response.data.success = true)) {
          localStorage.setItem("ID", response.data.data.id);
          localStorage.setItem("token", `Bearer ${response.data.data.token}`);
          navigate("/");
        } else {
          navigate("/login");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: login,
    onSubmit: (values, action) => {
      handlelogin();
    },
  });

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh", opacity: loading ? "0.5" : 1 }}
      >
        <Grid item>
          <Paper
            elevation={2}
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "5px",
              width: "480px",
              height: "380px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{
                mt: 3,
                "& .MuiFormControl-root": {
                  minWidth: "400px",
                },
              }}
            >
              <Box
                sx={{
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography variant="h5" sx={{ color: "black" }}>
                  Welcome Back
                </Typography>
              </Box>
              <Box sx={{ margin: "10px 0" }}>
              <FormControl variant="outlined">
                <Input
                  fullWidth
                  disabled={loading}
                  error={errors.email ? true : false}
                  placeholder="Email"
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={values.email}
                  onChange={handleChange}
                  variant="filled"
                  inputProps={{
                    style: {
                      padding: "15px",
                      background: "#f0f0f0",
                    },
                  }}
                />
                {errors.email ? (
                  <FormHelperText error id="standard-password">
                    {errors.email}
                  </FormHelperText>
                ) : null}
                </FormControl>
              </Box>
              <Box sx={{ margin: "10px 0" }}>
                <FormControl variant="outlined">
                  <Input
                    id="standard-password"
                    name="password"
                    disabled={loading}
                    sx={{ background: "#f0f0f0" }}
                    error={errors.password ? true : false}
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange}
                    variant="filled"
                    inputProps={{
                      style: {
                        padding: "15px",
                        disableUnderline: true,
                        border: "none",
                      },
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errors.password ? (
                    <FormHelperText error id="standard-password">
                      {errors.password}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Box>
              <Button
                fullWidth
                disabled={loading}
                type="submit"
                variant="contained"
                sx={{
                  fontSize: "16px",
                  fontWeight: "600",
                  margin: "25px auto",
                  display: "block",
                  backgroundColor: "#1976d2",
                  backgroundImage:
                    "linear-gradient(to right,  #1976d2 , #1976d2)",
                }}
              >
                Login
              </Button>
            </Box>
          </Paper>
        </Grid>

        <Box
          sx={{
            display: loading ? "block" : "none",
            position: "fixed",
            top: "50%",
            left: "50%",
          }}
        >
          <CircularProgress contentEditable={false} />
        </Box>
      </Grid>
    </>
  );
};

export default Login;
