import React, { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Home from "../Home/Home";
import AddStory from "../AddStory/AddStory";
import { ListAlt, Logout, Queue, Toc } from "@mui/icons-material";
import { Avatar, Button, CircularProgress, Modal } from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import Lessons from "../Lessons/Lessons";
import turtleBuddy from "../../assets/logo.png";

const drawerWidth = 240;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "20px",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const HomePage = ({ id }) => {
  const navigate = useNavigate();
  const { storyid } = useParams();
  const [storyId, setStoryId] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [openLogout, setOpenLogout] = useState(false);
  const theme = useTheme();

  const handleStoryId = (id) => {
    navigate(`/update-story/${id}`);
    setStoryId(id);
  };
  const handlePage = (index) => {
    setStoryId();
    index === 0 && navigate("/story-list");
    index === 1 && navigate("/create-story");
    index === 2 && navigate("/lesson");
  };

  const handleCloseLogout = () => {
    setOpenLogout(false);
  };
  const handleOpenLogout = (id) => {
    setOpenLogout(true);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const signoutHandler = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <Box
      sx={{
        display: "flex",
        opacity: loading ? 0.5 : 1,
      }}
    >
      <Modal
        open={openLogout}
        onClose={handleCloseLogout}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please Confirm
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {`Do you really want to Logout?`}
          </Typography>
          <Button
            style={{
              textTransform: "capitalize",
            }}
            onClick={handleCloseLogout}
          >
            Disagree
          </Button>
          <Button
            style={{
              textTransform: "capitalize",
            }}
            onClick={signoutHandler}
            autoFocus
          >
            Agree
          </Button>
        </Box>
      </Modal>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="h6" noWrap component="div">
              Dashboard
            </Typography>
            <Box>
              <Button
                onClick={handleOpenLogout}
                style={{
                  textTransform: "capitalize",
                }}
              >
                <Typography variant="p" noWrap sx={{ ml: 1, color: "#ffffff" }}>
                  Logout
                </Typography>
                <Avatar sx={{ bgcolor: "transparent" }}>
                  <Logout />
                </Avatar>
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        sx={{
          "& .MuiDrawer-docked": { backgroundColor: "#aaaaaa !important" },
          backgroundColor: "#aaaaaa !important",
        }}
      >
        <DrawerHeader sx={{ justifyContent: "space-between" }}>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
          >
            <Link to={"/"} style={{textDecoration:0}}>
            <Typography
              variant="h5"
              width={"100%"}
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color:"#3dbb3d"
                // color:"#1976d2"
              }}
            >
              Turtle Buddy
            </Typography>
            </Link>
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          sx={{
            height: "100vh",
          }}
        >
          {[
            { name: "Story List", icon: <ListAlt /> },
            { name: "Create Story", icon: <Queue /> },
            { name: "Lessons", icon: <Toc /> },
          ].map((text, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{ display: "block" }}
              onClick={(e) => handlePage(index)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  bgcolor: id === index ? "#dddddd !important" : "",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {text.icon}
                </ListItemIcon>
                <ListItemText
                  style={{ textTransform: "capitalize" }}
                  primary={text.name}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        sx={{
          display: loading ? "flex" : "none",
          position: "sticky",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          top: "70%",
          left: "50%",
        }}
      >
        <CircularProgress contentEditable={false} />
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Box>
          {id === 0 && (
            <Home
              handleStoryId={handleStoryId}
              setLoading={setLoading}
              loading={loading}
            />
          )}
          {id === 1 && (
            <AddStory
              handleStoryId={handleStoryId}
              storyId={storyid}
              setLoading={setLoading}
              loading={loading}
            />
          )}
          {id === 2 && <Lessons setLoading={setLoading} loading={loading} />}
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;
