import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  DeleteOutlined,
  Edit,
} from "@mui/icons-material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumnMenu,
  GridColumnMenuProps,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import axios from "axios";
import { BASE_URL } from "../Helper/APIURL";
import { toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "20px",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Home = ({ handleStoryId, setLoading, loading }) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [age, setAge] = useState("all");
  const [category, setCategory] = useState("all");
  const [lessonList, setLessonList] = useState([]);
  const [rows, setRows] = useState([]);

  function CustomColumnMenu(props: GridColumnMenuProps) {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenuColumnsItem: null,
        }}
      />
    );
  }

  const handleClose = () => {
    setDeleteId();
    setOpen(false);
  };
  const handleOpen = (id) => {
    setDeleteId(id);
    setOpen(true);
  };
  const handleAgeChange = (e) => {
    setAge(e.target.value);
    getLessonByAge(e.target.value);
  };
  const handleCategoryChange = (e) => {
    setCategory(e);
    setFilter(true);
  };
  const handleEditClick = (id) => {
    handleStoryId(id);
  };

  const handleDeleteClick = (id) => {
    const logintoken = localStorage.getItem("token");

    const config = {
      method: "delete",
      url: BASE_URL + `story/delete-story/${id}`,
      headers: {
        Authorization: logintoken,
      },
    };

    axios(config)
      .then((response) => {
        toast.success(response?.data?.message);
        handleFilter();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.data?.message);
      });

    handleClose();
  };

  const handleFilter = (cat) => {

    setLoading(true);

    const logintoken = localStorage.getItem("token");
    const config = {
      method: "get",
      url:
        age === "all"
          ? BASE_URL +
            `story/get-all-story?lessonId=${
              cat === "all" ? "" : category === "all" ? "" : category
            }`
          : category === "all"
          ? BASE_URL + `story/get-all-story?ageGroup=${age}`
          : BASE_URL + `story/get-all-story?lessonId=${category}`,
      headers: {
        Authorization: logintoken,
      },
    };

    axios(config)
      .then((response) => {
        setRows(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    setFilter(false);
  };

  useEffect(() => {
    handleFilter();
  }, [age, category]);

  const handleLessonList = (list) => {
    setLessonList(list);
  };

  const getLessonByAge = (age) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const config = {
      method: "get",
      url:
        age === "all"
          ? BASE_URL + `lesson/get-all-lesson`
          : BASE_URL + `lesson/get-all-lesson?ageGroup=${age}`,
      headers: {
        Authorization: token,
      },
    };

    axios(config)
      .then((response) => {
        handleLessonList(response.data.data);
        setCategory("all");
        if (!response.data.data[0]?._id) {
          setRows([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    const logintoken = localStorage.getItem("token");
    if (!logintoken) {
      navigate("/login");
    }
    getLessonByAge(age);
  }, []);

  const columns = [
    {
      field: "title",
      headerName: "Title",
      headerClassName: "tab-header-title",
      width: 450,
      editable: false,
    },
    {
      field: "lessonId",
      headerName: "Lesson Name",
      headerClassName: "tab-header-category",
      editable: false,
      width: 160,
      valueGetter: (params: GridValueGetterParams) => {
        return lessonList.filter((story) => {
          return story._id === params.row.lessonId;
        })[0]?.lessionName;
      },
    },
    {
      field: "ageGroup",
      headerName: "Age",
      headerClassName: "tab-header-age",
      width: 125,
      editable: false,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) => {
        const a = params.row.ageGroup.split("-");
        return a[1] ? `${a[0]} to ${a[1]}` : "+18";
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "tab-header-id",
      width: 125,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={(e) => handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteOutlined />}
            label="Delete"
            onClick={(e) => handleOpen(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Grid
      container
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="start"
      width="100%"
      minWidth="750px"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
      >
        <Typography
          variant="h5"
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontWeight: "bold",
          }}
        >
          Story List
        </Typography>
      </Box>
      <Grid
        container
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={2}
          gap={3}
        >
          <Box sx={{ minWidth: "150px", maxWidth: "250px" }}>
            <FormControl fullWidth>
              <InputLabel id="Age-select-label">Age</InputLabel>
              <Select
                disabled={loading}
                labelId="Age-select-label"
                id="Age-select"
                value={age}
                label="Age"
                displayEmpty
                onChange={handleAgeChange}
              >
                <MenuItem value={"all"}>All Age Group</MenuItem>
                <MenuItem value={"5-8"}>5 To 8</MenuItem>
                <MenuItem value={"9-13"}>9 To 13</MenuItem>
                <MenuItem value={"14-17"}>14 To 17</MenuItem>
                <MenuItem value={"18"}>+18 up</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ minWidth: "250px", maxWidth: "350px" }}>
            <FormControl fullWidth>
              <InputLabel id="Category-select-label">
                {lessonList.length === 0 ? "No Lesson Found" : "Lesson"}
              </InputLabel>
              <Select
                disabled={loading}
                labelId="Category-select-label"
                id="Category-select"
                label={lessonList.length === 0 ? "No Lesson Found" : "Lesson"}
                value={lessonList.length === 0 ? "" : category}
                displayEmpty
                onChange={(e) => handleCategoryChange(e.target.value)}
              >
                {lessonList.length === 0 ? (
                  <MenuItem disabled value="">
                    No Lesson Found
                  </MenuItem>
                ) : (
                  <MenuItem value="all">All Lesson</MenuItem>
                )}
                {lessonList.map((lesson, index) => {
                  return (
                    <MenuItem key={index} value={lesson._id}>
                      {lesson.lessionName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Grid>
      <Grid
        mt={2}
        container
        display="flex"
        flexDirection="column"
        justifyContent="start"
        alignItems="center"
        minWidth="700px"
      >
        <Box>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Please Confirm
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {`Do you really want to delete story: "${
                  rows.filter((story) => {
                    return story.id === deleteId;
                  })[0]?.title
                }"?`}
              </Typography>
              <Button
                style={{
                  textTransform: "capitalize",
                }}
                onClick={handleClose}
              >
                Disagree
              </Button>
              <Button
                style={{
                  textTransform: "capitalize",
                }}
                onClick={(e) => handleDeleteClick(deleteId)}
                autoFocus
              >
                Agree
              </Button>
            </Box>
          </Modal>
          <Box>
            <DataGrid
              disableRowSelectionOnClick={true}
              className="list-table"
              slots={{
                columnMenu: CustomColumnMenu,
                noRowsOverlay: () => (
                  <Box
                    sx={{
                      m: 1,
                      width: "100%",
                      textAlign: "center",
                      textTransform: "capitalize",
                    }}
                  >
                    No Data Found
                  </Box>
                ),
              }}
              sx={{
                minHeight: "125px",
                justifyContent: "space-between",
                "& .css-qvtrhg-MuiDataGrid-virtualScroller": {
                  height: rows.length === 0 ? "35px !important" : "100%",
                },
                "& .tab-header-id": {
                  backgroundColor: "#dddddd",
                },
                "& .tab-header-title": {
                  backgroundColor: "#dddddd",
                },
                "& .tab-header-category": {
                  backgroundColor: "#dddddd",
                },
                "& .tab-header-age": {
                  backgroundColor: "#dddddd",
                },
              }}
              rows={rows}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { pageSize: 5 } },
              }}
              pageSizeOptions={[5, 10, 25]}
              checkboxSelection={false}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Home;
