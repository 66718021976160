import * as Yup from "yup";

export const login = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string()
    .min(8)
    .required("Please enter password")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must contain 8 characters, contains uppercase, lowercase, number and special case character"
    ),
});
