import { Avatar, Box, Button } from "@mui/material";
import React from "react";
import notfound from "../../assets/NoData-amico.svg";
import { Home } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  const handelHome = () => {
    navigate("/");
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Avatar
        src={notfound}
        style={{
          width: "40%",
          height: "40%",
          minWidth: "300px",
          minHeight: "300px",
        }}
      />
      <Button
        component="label"
        variant="contained"
        startIcon={<Home />}
        name="gotohome"
        onClick={handelHome}
        sx={{
          fontSize: "16px",
          bgcolor: "transparent !important",
          boxShadow: "0 !important",
          border: "0 !important",
          color: "#000000 !important",
        }}
        style={{ textTransform: "capitalize" }}
      >
        Go to Home
      </Button>
    </Box>
  );
};

export default NotFound;
