import { AddPhotoAlternate, Delete } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL } from "../Helper/APIURL";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddStory = ({ handleStoryId, storyId, setLoading, loading }) => {
  const navigate = useNavigate();

  const [age, setAge] = useState("5-8");
  const [category, setCategory] = useState("");
  const [lessonList, setLessonList] = useState([]);

  const [title, setTitle] = useState("");
  const [error, setError] = useState({ title: "" });

  const [fullStory, setFullStory] = useState([
    {
      id: 0,
      text: "",
      image: "",
      IsImageUpdated: false,
      IsImageDeleted: false,
    },
  ]);
  const [imageurl, setImageurl] = useState([{ id: 0, url: "" }]);

  // get lesson list by ageGroup //
  const getLessonByAge = (age, lessonId) => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const config = {
      method: "get",
      url: BASE_URL + `lesson/get-all-lesson?ageGroup=${age}`,
      headers: {
        Authorization: token,
      },
    };
    axios(config)
      .then((response) => {
        setLessonList(response.data.data);
        if (!response.data.data[0]?._id && !lessonId) {
          setCategory();
        } else {
          setCategory(lessonId ? lessonId : response.data?.data[0]?._id);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // handleAgeChange //

  const handleAgeChange = (e) => {
    getLessonByAge(e.target.value);
    setAge(e.target.value);
  };

  // handleCategoryChange //

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  // handleChangeTitle //

  const handleChangeTitle = (e) => {
    if (e.target.value.trim() === "") {
      setError({ title: "Please enter title" });
    } else if (error.title !== "") {
      setError({ title: "" });
    }
    setTitle(e.target.value);
  };

  // handleChangeText //

  const handleChangeText = (e, id) => {
    setFullStory([
      ...fullStory.slice(0, id),
      { ...fullStory[id], text: e.target.value },
      ...fullStory.slice(id + 1),
    ]);
  };

  useEffect(() => {
    const logintoken = localStorage.getItem("token");
    const admin_id=localStorage.getItem("ID");
    if (!logintoken&&!admin_id) {
      navigate("/login");
    }
    storyId && setLoading(true);

    const config = {
      method: "get",
      url: BASE_URL + `story/get-story/${storyId}`,
      headers: {
        Authorization: logintoken,
      },
    };

    storyId &&
      axios(config)
        .then((response) => {
          setFullStory(response.data.data.story);
          setImageurl(
            response.data.data.story.map((story) => {
              return { id: story.id, url: story.image };
            })
          );
          setAge(response.data.data.ageGroup);
          getLessonByAge(
            response.data.data.ageGroup,
            response.data.data.lessonId
          );
          setTitle(response.data.data.title);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          error.response.data?.message &&
            toast.error(error?.response?.data?.message);
          error.response.data?.message && navigate("/story-list");
          setLoading(false);
        });
    getLessonByAge(age);
  }, []);

  // handleSubmit //

  const handleSubmit = () => {
    if (title === "") {
      setError({ title: "Please enter title" });
      return;
    }
    if (category === undefined) {
      toast.error("Please enter lesson");
      return;
    }

    if (fullStory.length === 1) {
      if (fullStory[0].text === "") {
        toast.error("Please enter story text");
        return;
      }
    }

    setLoading(true);
    const logintoken = localStorage.getItem("token");
    const admin_id=localStorage.getItem("ID");
    const s = fullStory.filter((story) => {
      return story.text.trim() !== "" || story.image !== "";
    });
    const story = s.map((story, index) => {
      return { ...story, id: index };
    });

    const data = {
      title: title,
      ageGroup: age,
      lessonId: category,
      story: story,
    };
    const config = {
      method: storyId ? "patch" : "post",
      url: storyId
        ? BASE_URL + `story/update-story/${storyId}`
        : BASE_URL + "story/add-story",
      headers: {
        Authorization: logintoken,
        admin_id:admin_id
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        toast.success(
          storyId ? "Story updated successfully" : "Story created successfully"
        );
        navigate("/story-list");
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
        toast.error(error?.response?.data?.message);
        setLoading(false);
      });
  };

  // handleAddText //

  const handleAddText = () => {
    setFullStory([
      ...fullStory,
      {
        id: fullStory.length,
        text: "",
        image: "",
        IsImageUpdated: false,
        IsImageDeleted: false,
      },
    ]);
    setImageurl([...imageurl, { id: imageurl.length, url: "" }]);
  };

  // handleAddImage  //

  const handleAddImage = (e, id) => {
    setLoading(true);
    const logintoken = localStorage.getItem("token");
    const ID = localStorage.getItem("ID");

    if (!logintoken&&!ID) {
      navigate("/login");
    }

    const file = e.target?.files[0];

    const isValidFileUploaded=(file)=>{
      const validExtensions = ['png','jpeg','jpg']
      const fileExtension = file?.type.split('/')[1]
      return validExtensions.includes(fileExtension)
    }
    if(!isValidFileUploaded(file)){
      file && toast.error(`Image must be ".png" or ".Jpeg" or ".jpg"`);
      setLoading(false);
      return;
    }



    setImageurl([
      ...imageurl.slice(0, id),
      {
        ...imageurl[id],
        url: URL.createObjectURL(file),
      },
      ...imageurl.slice(id + 1),
    ]);

    // /img-upload
    const FormData = require("form-data");
    const data = new FormData();
    data.append("img", file);
    const config = {
      method: "post",
      url: BASE_URL + "story/img-upload",
      headers: {
        Authorization: logintoken,
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        if ((response.data.success = true)) {
          setFullStory([
            ...fullStory.slice(0, id),
            {
              ...fullStory[id],
              image: response.data.data,
              IsImageUpdated: true,
              IsImageDeleted: false,
            },
            ...fullStory.slice(id + 1),
          ]);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // handleDeleteImage //

  const handleDeleteImage = (id) => {
    setImageurl([
      ...imageurl.slice(0, id),
      {
        ...imageurl[id],
        url: "",
      },
      ...imageurl.slice(id + 1),
    ]);

    setFullStory([
      ...fullStory.slice(0, id),
      {
        ...fullStory[id],
        image: "",
        IsImageUpdated: true,
        IsImageDeleted: true,
      },
      ...fullStory.slice(id + 1),
    ]);
  };

  //  handleNewStory  //

  return (
    <>
      <Box width={"100%"} pb={2} minWidth={"750px"}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box width={"100%"} mb={2} display={"block"} textAlign={"center"}>
            <Typography
              variant="h5"
              width={"100%"}
              style={{
                textAlign: "center",
                marginTop: "20px",
                fontWeight: "bold",
              }}
            >
              {storyId ? "Edit Story" : "Create Story"}
            </Typography>
          </Box>

          {/* title box and title error */}
          <Box
            sx={{
              minWidth: "100px",
              maxWidth: "90%",
              width: "90%",
              "& .MuiTextField-root": {
                width: "100%",
                borderRadius: "4px",
                boxShadow:
                  "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
              },
            }}
          >
            <TextField
              variant="outlined"
              label="Title"
              name="title"
              value={title}
              placeholder="Title"
              onChange={handleChangeTitle}
              size="small"
            />
          </Box>
          {error.title && (
            <p
              style={{
                fontSize: "14px",
                color: "red",
                marginTop: "3px",
              }}
            >
              {error.title}
            </p>
          )}

          <Box
            my={4}
            gap={3}
            sx={{
              minWidth: "400px",
              maxWidth: "90%",
              width: "90%",
            }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {/* Select Age */}
            <Box sx={{ minWidth: "100px", maxWidth: "550px", width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="Age-select-label">Age</InputLabel>
                <Select
                  labelId="Age-select-label"
                  id="Age-select"
                  name="age"
                  value={age}
                  label="Age"
                  sx={{
                    boxShadow:
                      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                  }}
                  onChange={handleAgeChange}
                >
                  <MenuItem value={"5-8"}>5 To 8</MenuItem>
                  <MenuItem value={"9-13"}>9 To 13</MenuItem>
                  <MenuItem value={"14-17"}>14 To 17</MenuItem>
                  <MenuItem value={"18"}>+18 up</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {/* Select Category */}
            <Box sx={{ minWidth: "100px", maxWidth: "550px", width: "100%" }}>
              <FormControl fullWidth>
                <InputLabel id="Category-select-label">
                  {lessonList.length === 0 ? "No Lesson Found" : "Lesson"}
                </InputLabel>
                <Select
                  labelId="Category-select-label"
                  id="Category-select"
                  name="Category"
                  sx={{
                    boxShadow:
                      "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                  }}
                  label={lessonList.length === 0 ? "No Lesson Found" : "Lesson"}
                  value={lessonList.length === 0 ? "" : category}
                  onChange={handleCategoryChange}
                >
                  {lessonList.length === 0 && (
                    <MenuItem disabled value="">
                      No Lesson Found
                    </MenuItem>
                  )}
                  {lessonList?.map((lesson, index) => {
                    return (
                      <MenuItem key={index} value={lesson._id}>
                        {lesson.lessionName}
                      </MenuItem>
                    );
                  })}
                  {/* {lessonList.length===0&&<MenuItem key={"01"} value={undefined}>No Lesson Found</MenuItem>} */}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            mt={2}
            py={2}
            border="1px solid #000000"
            boxShadow="0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
            borderRadius={"4px"}
            display={"flex"}
            justifyContent={"center"}
            flexDirection={"column"}
            alignItems={"center"}
            sx={{ minWidth: "300px", width: "90%" }}
          >
            {/* story */}
            {fullStory?.map((story, index) => {
              return (
                <Box
                  key={index}
                  display={"flex"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  minWidth={"100px"}
                  width={"90%"}
                >
                  {/* text box */}
                  <Box
                    key={"TextBox " + (index + 1)}
                    component="form"
                    noValidate
                    autoComplete="off"
                    sx={{
                      my: 1,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "flex-end",
                      width: "100%",
                      "& .MuiTextField-root": {
                        m: 1,
                        width: "100%",
                        borderRadius: "4px",
                        boxShadow:
                          "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                      },
                    }}
                  >
                    <TextField
                      multiline
                      rows={8}
                      value={story.text}
                      variant="outlined"
                      placeholder={"Text " + (index + 1)}
                      label={"Text " + (index + 1)}
                      onChange={(e) => handleChangeText(e, index)}
                    />
                  </Box>
                  {/* Edit and delete img btn */}
                  <Box
                    m={2}
                    sx={{
                      display:
                        imageurl[index]?.url !== ""
                          ? "flex"
                          : story.image !== ""
                          ? "flex"
                          : "none",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {/* Edit img btn */}
                    <Button
                      component="label"
                      variant="contained"
                      startIcon={<AddPhotoAlternate />}
                      sx={{
                        fontSize: "16px",
                        bgcolor: "transparent !important",
                        boxShadow: "0 !important",
                        border: "0 !important",
                        color: "#000000 !important",
                      }}
                      style={{ textTransform: "capitalize" }}
                    >
                      Edit image {story.id + 1}
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(e) => handleAddImage(e, index)}
                      />
                    </Button>
                    {/* delete img btn */}
                    <Button
                      component="label"
                      variant="contained"
                      onClick={(e) => handleDeleteImage(story.id)}
                      startIcon={<Delete />}
                      sx={{
                        fontSize: "16px",
                        bgcolor: "transparent !important",
                        boxShadow: "0 !important",
                        border: "0 !important",
                        color: "#000000 !important",
                      }}
                      style={{ textTransform: "capitalize" }}
                    >
                      Delete image {story.id + 1}
                    </Button>
                  </Box>
                  {/* img and add img btn */}
                  <Box
                    m={1}
                    sx={{
                      display: "flex",
                      justifyContent:"center",
                      alignItems: "flex-end",
                      minWidth: "100px",
                      maxWidth: "90%",
                      width: "100%",
                      minHeight: "100px",
                    }}
                  >
                    <Avatar
                      sx={{
                        borderRadius: "0 !important",
                        inlineSize: "full",
                        width: "70%",
                        height: "70%",
                        display:
                          imageurl[index].url !== ""
                            ? "block"
                            : story.image
                            ? "block"
                            : "none",
                      }}
                      style={{
                        inset: "0 !important",
                      }}
                      src={
                        imageurl[index].url !== ""
                          ? imageurl[index].url
                          : story.image
                      }
                    />
                    {/* Add Image btn */}
                    <Button
                      component="label"
                      variant="contained"
                      startIcon={<AddPhotoAlternate />}
                      sx={{
                        fontSize: "16px",
                        bgcolor: "transparent !important",
                        boxShadow: "0 !important",
                        border: "0 !important",
                        color: "#000000 !important",
                        display:
                          imageurl[index].url !== ""
                            ? "none"
                            : story.image
                            ? "none"
                            : "flex",
                      }}
                      style={{ textTransform: "capitalize" }}
                    >
                      Add image {" " + (index + 1)}
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(e) => handleAddImage(e, index)}
                      />
                    </Button>
                  </Box>
                </Box>
              );
            })}
            {/* Add Text field and image btn */}
            <Button
              variant="contained"
              onClick={handleAddText}
              sx={{
                mt: 1,
                width: "90%",
                fontSize: "16px",
                boxShadow: "0 !important",
                border: "0 !important",
              }}
              style={{ textTransform: "capitalize" }}
            >
              Add Text field
            </Button>
          </Box>
          {/* submit btn */}
          <Button
            fullWidth
            type="submit"
            name="submit"
            variant="contained"
            onClick={handleSubmit}
            style={{
              textTransform: "capitalize",
            }}
            sx={{
              minWidth: "100px",
              maxWidth: "250px",
              fontSize: "16px",
              fontWeight: "600",
              margin: "25px auto",
              display: "block",
              backgroundColor: "#1976d2",
              backgroundImage: "linear-gradient(to right,  #1976d2 , #1976d2)",
            }}
          >
            {storyId ? "save" : "Submit"}
            {/* Submit */}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AddStory;
