import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Login from "./Components/Login/Login";
import NotFound from "./Components/NotFound/NotFound";
import HomePage from "./Components/HomePage/HomePage";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
function App() {
  return (
    <>
      <ToastContainer autoClose={2000} />
      <Router>
        <Routes>
          <Route path="/" element={<HomePage id={0} />}></Route>
          <Route exact path="/story-list" element={<HomePage id={0} />}></Route>
          <Route
            exact
            path="/create-story"
            element={<HomePage id={1} />}
          ></Route>
          <Route
            exact
            path="/update-story/:storyid"
            element={<HomePage id={1} />}
          ></Route>
          <Route exact path="/lesson" element={<HomePage id={2} />}></Route>
          <Route exact path="/login" element={<Login />}></Route>
          <Route path="*" element={<NotFound />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
