import React, { useEffect, useState } from "react";
import { AddPhotoAlternate, DeleteOutlined, Edit } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import {
  DataGrid,
  GridActionsCellItem,
  GridColumnMenu,
  GridColumnMenuProps,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BASE_URL } from "../Helper/APIURL";
import { toast } from "react-toastify";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "20px",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
};

const Lessons = ({ setLoading, loading }) => {
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState();
  const [editId, setEditId] = useState();
  const [lessonName, setLessonName] = useState("");
  const [errorName,setErrorName]=useState(false);
  const [errorImg,setErrorImg]=useState(false);
  const [age, setAge] = useState("all");
  const [ageEdit, setAgeEdit] = useState("5-8");
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [rows, setRows] = useState([]);

  function CustomColumnMenu(props: GridColumnMenuProps) {
    return (
      <GridColumnMenu
        {...props}
        slots={{
          columnMenuColumnsItem: null,
        }}
      />
    );
  }

  const handleErrorName=(e)=>{
    e?.trim()?setErrorName(false):setErrorName(true);
  }

  const handleAge = (e) => {
    setAge(e.target.value);
  };
  const handleAgeEdit = (e) => {
    setAgeEdit(e.target.value ? e.target.value : age);
  };

  const handleOpenEdit = (id) => {
    id && setEditId(id);
    id &&
      setLessonName(
        rows.filter((story) => {
          return story._id === id;
          //   })[0]?.lessonName}
        })[0]?.lessionName
      );
      id && setAgeEdit(
        rows.filter((story) => {
          return story._id === id;
          //   })[0]?.lessonName}
        })[0]?.ageGroup)
      id && setImageUrl(
        rows.filter((story) => {
          return story._id === id;
          //   })[0]?.lessonName}
        })[0]?.lessonImage)
    setOpenEdit(true);
  };
  const handleEditClose = () => {
    setEditId();
    setErrorName(false);
    setErrorImg(false);
    setAgeEdit("5-8");
    setImageUrl("");
    setLessonName("");
    setOpenEdit(false);
  };
  const handleOpenDelete = (id) => {
    setDeleteId(id);
    setOpenDelete(true);
  };
  const handleDeleteClose = () => {
    setDeleteId();
    setOpenDelete(false);
  };
  const handlelessonName = (e) => {
    handleErrorName(e.target.value);
    setLessonName(e.target.value);
  };

  const handleDelete = () => {
    // for id editId
    const token = localStorage.getItem("token");
    const config = {
      method: "delete",
      url: BASE_URL + `lesson/delete-lesson/${deleteId}`,
      headers: {
        Authorization: token,
      },
    };
    //Api to delete lesson by id

    axios(config)
      .then((response) => {
        toast.success(response?.data?.message);
        setLoading(false);
        getLessonByAge();
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.data?.message);
        getLessonByAge();
        setLoading(false);
      });
    handleDeleteClose();
  };

  const handleAddImage = (e) => {
    const file=e.target?.files[0];

    const isValidFileUploaded=(file)=>{
      const validExtensions = ['png','jpeg','jpg']
      const fileExtension = file?.type.split('/')[1]
      return validExtensions.includes(fileExtension)
    }
    if(isValidFileUploaded(file)){
      e.target?.files[0]&&setImageUrl(URL.createObjectURL(e.target?.files[0]));
      e.target?.files[0]&&setImage(e.target?.files[0]);
      e.target?.files[0]&&setErrorImg(false);
    }else{
      !imageUrl&&setErrorImg(`icon must be ".png" or ".Jpeg" or ".jpg"`);
    }
  }

  const handleEdit = () => {
    if (!lessonName.trim()) {
      setErrorName(true);
      if (imageUrl==="") {
        setErrorImg(true);
      }
      return;
    }
    if (imageUrl==="") {
      setErrorImg(true);
      return;
    }

    const FormData = require("form-data");
    const data = new FormData();
    data.append("lessonImage", image?image:imageUrl);
    data.append("lessionName",lessonName?.trim());
    data.append("ageGroup",ageEdit);
    setLoading(true);
    const token = localStorage.getItem("token");
    const admin_id = localStorage.getItem("ID");
    const config = {
      method: editId ? "patch" : "post",
      url: editId
        ? BASE_URL + `lesson/update-lesson/${editId}`
        : BASE_URL + `lesson/add-lesson`,
      headers: {
        Authorization: token,
        admin_id:admin_id,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        toast.success(response?.data?.message);
        setLessonName("");
        getLessonByAge();
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message);
        getLessonByAge();
        setLoading(false);
      });
    handleEditClose();
  };

  const columns = [
    {
      field: "lessonImage",
      headerName: "Icon",
      headerClassName: "tab-header-age",
      width: 100,
      editable: false,
      sortable: false,
      filterable: false,
      columnMenuSortItem:false,
      renderCell: (params) => (<img style={{textAlign:"contain",height:"40px",width:"40px"}} src={params.value} alt={"Img"} />)
    },
    {
      // field: "lessonName",
      field: "lessionName",
      headerName: "Lesson Name",
      headerClassName: "tab-header-title",
      width: 400,
      editable: false,
    },
    {
      field: "ageGroup",
      headerName: "Age",
      headerClassName: "tab-header-age",
      width: 125,
      editable: false,
      valueGetter: (params: GridValueGetterParams) => {
        const a = params.row.ageGroup.split("-");
        return a[1] ? `${a[0]} to ${a[1]}` : "+18";
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      headerClassName: "tab-header-id",
      width: 125,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={(e) => handleOpenEdit(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteOutlined />}
            label="Delete"
            onClick={(e) => handleOpenDelete(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const getLessonByAge = () => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const config = {
      method: "get",
      url:
        BASE_URL + `lesson/get-all-lesson?ageGroup=${age === "all" ? "" : age}`,
      headers: {
        Authorization: token,
      },
    };

    axios(config)
      .then((response) => {
        setRows(
          response.data.data.map((lesson) => {
            return { id: lesson._id, ...lesson };
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    // setRows
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login");
    }
    getLessonByAge();
  }, [age]);

  return (
    <Box width={"100%"} pb={2} minWidth={"750px"}>
      <Box
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
      >
        <Typography
          variant="h5"
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontWeight: "bold",
          }}
        >
          Lessons
        </Typography>
      </Box>

      {/* for delete lasson  */}
      <Modal
        open={openDelete}
        key={"deleteModal"}
        onClose={handleDeleteClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Please Confirm
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {`Do you really want to delete Lesson: "${
              rows.filter((story) => {
                return story.id === deleteId;
                //   })[0]?.lessonName
              })[0]?.lessionName
            }"?`}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            If you delete this Lesson, Than it's stories will be deleted
            automatically
          </Typography>
          <Button
            style={{
              textTransform: "capitalize",
            }}
            onClick={handleDeleteClose}
          >
            Close
          </Button>
          <Button
            style={{
              textTransform: "capitalize",
            }}
            onClick={handleDelete}
            autoFocus
          >
            Agree
          </Button>
        </Box>
      </Modal>
      {/* for edit lasson  */}
      <Modal
        open={openEdit}
        key={"editModal"}
        onClose={handleEditClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {editId ? "Edit Lesson " : "Add Lesson "}
            {editId
              ? `"${
                  rows.filter((story) => {
                    return story.id === editId;
                    // })[0]?.lessonName}"`}
                  })[0]?.lessionName
                }"`
              : ""}
          </Typography>

          <Box sx={{ mt: 2, mb: 2, minWidth: "150px", maxWidth: "100%" }}>
            <FormControl fullWidth>
              <InputLabel id="Age-select-label">Age</InputLabel>
              <Select
                labelId="Age-select-label"
                id="Age-select"
                value={ageEdit}
                label="Age"
                displayEmpty
                onChange={handleAgeEdit}
              >
                <MenuItem value={"5-8"}>5 To 8</MenuItem>
                <MenuItem value={"9-13"}>9 To 13</MenuItem>
                <MenuItem value={"14-17"}>14 To 17</MenuItem>
                <MenuItem value={"18"}>+18 up</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth error={errorName}>
              <TextField
                fullWidth
                sx={{ mt: 2 }}
                label="lesson Name"
                variant="outlined"
                style={{
                  textTransform: "capitalize",
                }}
                value={lessonName}
                onChange={handlelessonName}
              />
              {errorName&& <FormHelperText sx={{fontSize:"15px"}}>Please Enter Lesson Name</FormHelperText>}
            </FormControl>
            <FormControl sx={{mt:1 ,display:"flex", justifyContent:"center",alignItems:"center",flexDirection:"column"}} fullWidth error={errorImg}>
             <Box  sx={{
                        width: "90px",
                        height: "90px",
                        display:
                        imageUrl!== ""
                          ? "flex"
                          : "none",}}>
              <Avatar className="lesson-img"
                      sx={{
                        borderRadius: "0 !important",
                        textAlign: "center",
                        width: "100%",
                        height: "100%",
                        objectFit: "contain !important",
                      }}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain !important",
                      }}
                      src={imageUrl}
                    />
                    </Box>
            <Button
                      component="label"
                      variant="contained"
                      startIcon={<AddPhotoAlternate />}
                      sx={{
                        my:1,
                        fontSize: "16px",
                        bgcolor: "transparent !important",
                        boxShadow: "0 !important",
                        border: "0 !important",
                        color: "#000000 !important",
                      }}
                      style={{ textTransform: "capitalize" }}
                    >
                       {imageUrl?"Edit lesson Icon":"Add Lesson Icon"} 
                      <VisuallyHiddenInput
                        type="file"
                        onChange={(e) => handleAddImage(e)}
                      />
                    </Button>
                    {errorImg&& <FormHelperText sx={{fontSize:"15px"}}>{errorImg===`icon must be ".png" or ".Jpeg" or ".jpg"`?`Icon must be ".png" or ".Jpeg" or ".jpg"`:"Please add image icon"}</FormHelperText>}
            </FormControl>
          </Box>

          <Button
            style={{
              textTransform: "capitalize",
            }}
            onClick={handleEditClose}
          >
            Close
          </Button>
          <Button
            style={{
              textTransform: "capitalize",
            }}
            onClick={(e) => handleEdit()}
            autoFocus
          >
            {editId ? "save" : "add"}
          </Button>
        </Box>
      </Modal>

      <Grid
        container
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={2}
          gap={3}
        >
          <Box sx={{ minWidth: "150px", maxWidth: "250px" }}>
            <FormControl fullWidth>
              <InputLabel id="Age-select-label">Age</InputLabel>
              <Select
                disabled={loading}
                labelId="Age-select-label"
                id="Age-select"
                value={age}
                label="Age"
                displayEmpty
                onChange={handleAge}
              >
                <MenuItem value={"all"}>All Lesson</MenuItem>
                <MenuItem value={"5-8"}>5 To 8</MenuItem>
                <MenuItem value={"9-13"}>9 To 13</MenuItem>
                <MenuItem value={"14-17"}>14 To 17</MenuItem>
                <MenuItem value={"18"}>+18 up</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Button
            fullWidth
            p={2}
            name="AddEdit"
            variant="contained"
            onClick={(e) => handleOpenEdit()}
            style={{
              textTransform: "capitalize",
            }}
            sx={{
              minWidth: "100px",
              maxWidth: "250px",
              fontSize: "16px",
              fontWeight: "600",
              margin: "25px auto",
              display: "block",
              backgroundColor: "#1976d2",
              backgroundImage: "linear-gradient(to right,  #1976d2 , #1976d2)",
            }}
          >
            Add lesson
          </Button>
        </Box>
        <Box>
          <DataGrid
            disableRowSelectionOnClick={true}
            className="list-table"
            slots={{
              columnMenu: CustomColumnMenu,
              noRowsOverlay: () => (
                <Box
                  sx={{
                    m: 1,
                    width: "100%",
                    textAlign: "center",
                    textTransform: "capitalize",
                  }}
                >
                  No Data found
                </Box>
              ),
            }}
            sx={{
              minHeight: "150px",
              justifyContent: "space-between",
              "& .css-qvtrhg-MuiDataGrid-virtualScroller": {
                height: rows.length === 0 ? "35px !important" : "100%",
              },
              "& .tab-header-id": {
                backgroundColor: "#dddddd",
              },
              "& .tab-header-title": {
                backgroundColor: "#dddddd",
              },
              "& .tab-header-category": {
                backgroundColor: "#dddddd",
              },
              "& .tab-header-age": {
                backgroundColor: "#dddddd",
              },
            }}
            rows={rows}
            columns={columns}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10, 25]}
            checkboxSelection={false}
          />
        </Box>
      </Grid>
    </Box>
  );
};

export default Lessons;
